import React, { useEffect, useState } from "react";
import { TrackingHistoryItem } from "./components/TrackingHistoryItem";
import { TrackingError } from "./components/TrackingError";
import { useParams } from "react-router";
import { Switch } from "@headlessui/react";
import StatOvernightIcon from "../../../../assets/icons/stat.png";
import useIsFirstMount from "../../../hooks/useIsFirstMount";
import BoxCheckIcon from "../../../../assets/icons/box-check.svg";
import Spinner from "../../../components/Spinner";
import Stepper from "./components/Stepper";
import moment from "moment";
import axios from "axios";

const possibleSteps = ["pending", "inforeceived", "intransit", "outfordelivery", "exception", "delivered", "returned"];

const TrackingPage = () => {
  const isFirstMount = useIsFirstMount();
  const { trackingNumber } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carrierName, setCarrierName] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  // const [textOptIn, setTextOptionIn] = useState(false);
  // const [emailOptIn, setEmailOptionIn] = useState(false);
  const [carrierIconLink, setCarrierIconLink] = useState("");
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [deleveryAddress, setDeliveryAddress] = useState("");
  const [currentTracking, setCurrentTracking] = useState(null);
  const [trackingHistory, setTrackingHistory] = useState(null);
  const [actualDeliveryDate, setActualDeliveryDate] = useState("");
  const [shouldUpdateStatus, setShouldUpdateStatus] = useState(false);
  const [defaultBGColor, setDefaultBGColor] = useState("");
  const [defaultTextColor, setDefaultTextColor] = useState("");
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState("");
  // const [displayCarrierLogo, setDisplayCarrierLogo] = useState(true);
  // const [displaySMSNotifications, setDisplaySMSNotifications] = useState(false);
  // const [displayEmailNotifications, setDisplayEmailNotifications] = useState(false);
  const [displayEstimatedDeliveryDate, setDisplayEstimatedDeliveryDate] = useState(true);

  useEffect(() => {
    setCarrierName("stat overnight");
    if (trackingNumber) {
      fetchTracking();
    } else {
      setError(true);
    }
  }, [trackingNumber, carrierName]);

  let CarrierIconObj = { logo: BoxCheckIcon, HeaderClasses: "mx-auto p-6 w-1/2 sm:w-auto", footerClasses: "w-32" };
  if (carrierName === "stat overnight") {
    CarrierIconObj = { logo: StatOvernightIcon, HeaderClasses: "mx-auto w-60", footerClasses: "w-32" };
    document?.styleSheets[2]?.insertRule(".lds-dual-ring:after { border-color: #dd2424 transparent #dd2424 transparent; }", 0);
  }

  useEffect(() => {
    if (isFirstMount) {
      return;
    }
    if (shouldUpdateStatus) {
      toogleNotification();
    }
  }, [shouldUpdateStatus]);

  const toogleNotification = () => {
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_PUBLIC_SERVICE_API_URL}/public/notifications/${trackingNumber}`, {
        textOptIn: textOptIn ? "true" : "false",
        emailOptIn: emailOptIn ? "true" : "false",
      })
      .finally(() => {
        setLoading(false);
        setShouldUpdateStatus(false);
      });
  };

  const fetchTracking = () => {
    if (!carrierName) return;
    setLoading(true);
    Promise.all([
      axios.get(`${process.env.REACT_APP_PUBLIC_SERVICE_API_URL}/public/carrierTrackingHistory/${carrierName}/${trackingNumber}`),
      axios.get(`${process.env.REACT_APP_PUBLIC_SERVICE_API_URL}/public/carrierCurrentTracking/${carrierName}/${trackingNumber}`),
      axios.get(`${process.env.REACT_APP_PUBLIC_SERVICE_API_URL}/public/trackingAppConfigurations/${trackingNumber}`),
    ])
      .then((response) => {
        //  this style should be set dynamically
        if (carrierName === "stat overnight") {
          setDefaultTextColor("#dd2424");
          setDefaultBGColor("#dd2424");
        } else {
          setDefaultTextColor("#1482BA");
          setDefaultTextColor("#707070C2");
        }
        // 
        setTrackingHistory(response[0]?.data?.data);
        setCurrentTracking(response[1]?.data?.data);
        if (response[1]?.data?.statusCode === 200 && response[1]?.data?.data) {
          const data = response[1]?.data?.data;
          setLastUpdatedTime(data?.receivedDate || "");
          setDeliveryAddress(data?.deliveryAddress || "");
          const currentStatus = data?.tag?.toLowerCase()?.replace(/\s/g, "");
          const index = possibleSteps.indexOf(currentStatus);
          if (index >= 0) {
            setActiveStep(index + 1);
          }
        }
        if (response[2]?.data?.statusCode === 200 && response[2]?.data?.data) {
          const data = response[2]?.data?.data;
          let estDeleiveryDate = data?.estimatedDeliveryDate || "";
          if (estDeleiveryDate) {
            var new_date = moment(estDeleiveryDate).add(+data?.daysToAddInEstimatedDeliveryDate || 0, "days");
            estDeleiveryDate = new_date._d;
          }
          setEstimatedDeliveryDate(estDeleiveryDate);
          if (response[0]?.data?.data) {
            const shipmentHistory = response[0]?.data?.data;
            if (shipmentHistory && shipmentHistory.length) {
              const deliveredItem = shipmentHistory.find((item) => item.tag === "Delivered");
              if (deliveredItem) {
                setActualDeliveryDate(deliveredItem?.checkPointTime);
              }
            }
          }
          setCarrierIconLink(data?.iconLink || "");
          // setTextOptionIn(data?.textOptIn === "true" ? true : false);
          // setEmailOptionIn(data?.emailOptIn === "true" ? true : false);
          // setDisplayCarrierLogo(data?.displayCarrierLogo === null ? true : data?.displayCarrierLogo);
          // setDisplaySMSNotifications(data?.displaySMSNotifications === null ? false : data?.displaySMSNotifications);
          // setDisplayEmailNotifications(data?.displayEmailNotifications === null ? false : data?.displayEmailNotifications);
          setDisplayEstimatedDeliveryDate(data?.displayEstimatedDeliveryDate === null ? true : data?.displayEstimatedDeliveryDate);
        }
        setError(false);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="">
      {loading && <Spinner />}
      {carrierIconLink &&
        <div className="mt-5">
          <a href="https://statovernight.com/">
            <img className={CarrierIconObj.HeaderClasses} src={CarrierIconObj.logo} alt="Logo" />
          </a>
        </div>
      }
      {!error ? (
        <>
          <Stepper activeStep={activeStep} defaultBGColor={defaultBGColor} />
          <div className="container  mx-auto mb-5">
            <div className="w-3/4 flex flex-col-reverse md:flex-row items-center mx-auto mt-10 md:mt-6 space-y-0 md:space-y-0">
              {/* Left item */}
              <div className="flex flex-col items-center w-full md:w-1/2 md:items-start ">
                <div className="mb-1">
                  <p className="text-lg font-semibold  text-normal text-[#5e4444] dark:text-white mt-1 md:text-left text-center">
                    Support
                  </p>
                  <h1 className={"text-normal md:text-left text-center "} style={{ color: defaultTextColor }}>support@statovernight.com</h1>
                </div>
                {
                  displayEstimatedDeliveryDate ?
                    actualDeliveryDate === "" ?
                      <div>
                        <p className="text-lg font-semibold  text-normal  text-[#5e4444] dark:text-white  md:text-left text-center">Estimated Delivery Date</p>
                        <h1 className="text-normal md:text-left text-center " style={{ color: defaultTextColor }}>  {estimatedDeliveryDate ? moment(estimatedDeliveryDate).format("MMMM DD, YYYY") : ""}</h1>
                      </div>
                      : <div>
                        <p className="text-lg font-semibold text-normal text-[#5e4444] dark:text-white  md:text-left text-center ">Delivered Date</p>
                        <h1 className="text-normal md:text-left text-center " style={{ color: defaultTextColor }}>  {actualDeliveryDate ? moment(actualDeliveryDate).format("MMMM DD, YYYY") : ""}</h1>
                      </div>
                    : null
                }
                <div>
                  <p className="text-lg font-semibold  text-normal text-[#5e4444] dark:text-white mt-1 md:text-left text-center">
                    {
                      actualDeliveryDate === "" ? "Delivery Address" : "Delivered Address"
                    }
                  </p>
                  <h1 className={"text-normal md:text-left text-center "} style={{ color: defaultTextColor }}>{deleveryAddress}</h1>
                </div>

              </div>
              {/* Right item  */}
              <div className="flex flex-col items-center w-full md:w-1/2 md:items-end  space-y-1">
                <div>
                  <p className="text-lg font-semibold  text-normal text-[#5e4444] dark:text-white mt-1 md:text-right text-center">
                    Call Us
                  </p>
                  <h1 className={"text-normal md:text-left text-center "} style={{ color: defaultTextColor }}>972-479-9260</h1>
                </div>
                {/* <div className="flex flex-col justify-end">
                  {
                    displayEmailNotifications ? <div className="flex items-center mr-0  md:ml-0">
                      <Switch.Group>
                        <Switch.Label passive className={" text-sm "} style={{ color: defaultTextColor }}>
                          Send Email Alerts
                        </Switch.Label>
                        <Switch name="emailAlert" checked={emailOptIn} onChange={val => {
                          setEmailOptionIn(val);
                          setShouldUpdateStatus(true);
                        }} className={" relative inline-flex items-center h-6 rounded-full w-11 ml-2"}
                          style={{ backgroundColor: emailOptIn === true ? defaultBGColor : "#70707070" }}
                        >
                          <span className="sr-only">Enable notifications</span>
                          <span className={`${emailOptIn ? "translate-x-6" : "translate-x-1"}  inline-block w-4 h-4 transform bg-white rounded-full`} />
                        </Switch>
                      </Switch.Group>
                    </div> : null
                  }
                  {
                    displaySMSNotifications ? <div className="flex  mt-2 items-center mr-0 md:ml-0">
                      <Switch.Group>
                        <Switch.Label passive className={" text-sm "} style={{ color: defaultTextColor }}>
                          Send SMS Alerts
                        </Switch.Label>
                        <Switch name="phoneAlert" checked={textOptIn} onChange={val => {
                          setTextOptionIn(val);
                          setShouldUpdateStatus(true);
                        }} className={" relative inline-flex items-center h-6 rounded-full w-11  ml-4"}
                          style={{ backgroundColor: textOptIn === true ? defaultBGColor : "#70707070" }}
                        >
                          <span className="sr-only">Enable notifications</span>
                          <span className={`${textOptIn ? "translate-x-6" : "translate-x-1"}  inline-block w-4 h-4 transform bg-white rounded-full`} />
                        </Switch>
                      </Switch.Group>
                    </div> : null
                  }
                </div> */}

                <div>
                  <p className="text-lg font-semibold  text-normal text-[#5e4444] dark:text-white  md:text-right text-center">
                    Tracking No
                  </p>
                  <h1 className={"text-normal md:text-left text-center "} style={{ color: defaultTextColor }}>{currentTracking?.trackingNumber}</h1>
                </div>
                <div>
                  <p className="text-lg font-semibold  text-normal text-[#5e4444] dark:text-white mt-1 md:text-right text-center">
                    Last Updated
                  </p>
                  <h1 className={"text-normal md:text-left text-center "} style={{ color: defaultTextColor }}>{lastUpdatedTime ? moment(lastUpdatedTime).format("MMMM DD, YYYY") : ""}</h1>
                </div>

              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="w-3/4  mx-auto bg-white border border-[#70707054]" >
              <div className="border border-b-1 border-[#70707054] h-12 flex items-center " style={{ backgroundColor: defaultBGColor }}>
                <h1 className="text-xl align-middle mx-8 text-white">Tracking Events</h1>
              </div>
              <ul className="w-full text-sm font-medium text-gray-900 space-y-3">
                {trackingHistory &&
                  trackingHistory.length &&
                  trackingHistory.map((history, index) => {
                    return <TrackingHistoryItem key={index} {...history} defaultTextColor={defaultTextColor} defaultBGColor={defaultBGColor} />;
                  })}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <TrackingError />
      )
      }
      {
        carrierIconLink && <>
          <div className="flex flex-row justify-center mt-6 space-x-4">
            <p className="text-[#C6C7C4] text-sm">Powered by</p>
            <a href="https://statovernight.com/">
              <img className={CarrierIconObj.footerClasses} src={CarrierIconObj.logo} alt="Logo" />
            </a>
          </div>
          <div className="flex flex-row justify-center">
            <p className="text-[#C6C7C4] text-sm my-3">Copyright @ {new Date().getFullYear()}</p>
          </div>
        </>
      }
    </div >
  );

};

export default TrackingPage;
